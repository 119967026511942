// src/components/LoanRequestForm.js
import React, { useState } from 'react';

function LoanRequestForm() {
  const [amount, setAmount] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [witnesses, setWitnesses] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loanData = {
      debtorId: 1,  // Replace with actual debtor ID
      creditorId: 2, // Replace with actual creditor ID
      amount,
      dueDate,
      witnesses,
    };

    const response = await fetch('/api/request-loan', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loanData),
    });

    const result = await response.json();
    console.log(result.message);  // Display success message or handle error
  };

  return (
    <div>
      <h2>Request a Loan</h2>
      <p>This is the Loan request form.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="number"
          placeholder="Loan Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <input
          type="date"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
        />
        {/* Add witness inputs */}
        <button type="submit">Submit Loan Request</button>
      </form>
    </div>
  );
}

export default LoanRequestForm;
