// src/components/LoanApproval.js
import React, { useEffect, useState } from 'react';

function LoanApproval() {
  const [loanRequests, setLoanRequests] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch pending loan requests from backend
    const fetchLoanRequests = async () => {
      try {
        const response = await fetch('/api/get-pending-loans');
        if (response.ok) {
          const data = await response.json();
          setLoanRequests(data);
        } else {
          setError('Failed to load loan requests');
        }
      } catch (err) {
        console.error('Error fetching loan requests:', err);
        setError('Something went wrong');
      }
    };
    fetchLoanRequests();
  }, []);

  const handleApproval = async (loanId, approvalStatus) => {
    try {
      const response = await fetch('/api/approve-loan', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ loanId, approvalStatus }),
      });
      if (response.ok) {
        setLoanRequests((prev) =>
          prev.filter((loan) => loan.id !== loanId)
        );
      } else {
        setError('Failed to update loan status');
      }
    } catch (err) {
      console.error('Error updating loan status:', err);
      setError('Something went wrong, please try again');
    }
  };

  return (
    <div>
      <h2>Loan Approval</h2>
      <p>This is the Loan Approval page.</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <ul>
        {loanRequests.map((loan) => (
          <li key={loan.id}>
            <p>Amount: {loan.amount}, Due Date: {loan.dueDate}</p>
            <button onClick={() => handleApproval(loan.id, 'approved')}>Approve</button>
            <button onClick={() => handleApproval(loan.id, 'rejected')}>Reject</button>
          </li>
        ))}
      </ul>
      {loanRequests.length === 0 && <p>No pending loan requests.</p>}
    </div>
  );
}

export default LoanApproval;
