// src/components/LoanHistory.js
import React, { useEffect, useState } from 'react';

function LoanHistory() {
  const [loanHistory, setLoanHistory] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch loan history from backend
    const fetchLoanHistory = async () => {
      try {
        const response = await fetch('/api/get-loan-history');
        if (response.ok) {
          const data = await response.json();
          setLoanHistory(data);
        } else {
          setError('Failed to load loan history');
        }
      } catch (err) {
        console.error('Error fetching loan history:', err);
        setError('Something went wrong');
      }
    };
    fetchLoanHistory();
  }, []);

  return (
    <div>
      <h2>Loan History</h2>
      <p>This is the Loan history page.</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <ul>
        {loanHistory.map((loan) => (
          <li key={loan.id}>
            <p>
              Amount: {loan.amount}, Status: {loan.status}, Due Date: {loan.dueDate}
            </p>
          </li>
        ))}
      </ul>
      {loanHistory.length === 0 && <p>No loan history available.</p>}
    </div>
  );
}

export default LoanHistory;
