// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Auth from './components/Auth';
import LoanRequestForm from './components/LoanRequestForm';
import LoanApproval from './components/LoanApproval';
import LoanHistory from './components/LoanHistory';

function App() {
  return (
    <Router>
      <div>
        <h1>Welcome to Qarz-e-Hasna App</h1>
        <Routes>
          <Route exact path="/" component={Auth} />
          <Route path="/request-loan" component={LoanRequestForm} />
          <Route path="/approve-loan" component={LoanApproval} />
          <Route path="/loan-history" component={LoanHistory} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
