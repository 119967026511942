// src/index.js (React app entry point)
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/App.css';  // Include your styles

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
